import Logo from "../../img/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faYoutube,
  faTwitter,
  faFacebookSquare,
} from "@fortawesome/free-brands-svg-icons";
import {
  faChevronRight,
  faXmark,
  faPrint,
  faObjectGroup,
  faCropSimple,
  faCrop,
  faCamera,
  faUpload,
  faFileUpload,
  faMessage,
  faQuestion,
  faQuestionCircle,
  faImage,
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useState } from "react";
import { motion } from "framer-motion";

export const socials = [
  {
    id: "Instagram",
    href: "https://www.instagram.com/3dprintingcanberra/",
    icon: faInstagram,
  },
  {
    id: "Facebook",
    href: "https://www.facebook.com/profile.php?id=100089278913559",
    icon: faFacebookSquare
  }
];

/*
    { id: 'Facebook', href: 'https://www.facebook.com', icon: faFacebookSquare },
    { id: 'Twitter', href: 'https://www.facebook.com', icon: faTwitter },
*/

export const menuItems = [
  { text: "Home", href: "/" },
  { text: "About", href: "/about" },
  {
    text: "Services",
    href: "/services",
    subItems: [
      {
        text: "Designs",
        text2: "Custom designs & prototyping",
        href: "designs",
        icon: faObjectGroup,
      },
      {
        text: "Scanning",
        text2: "3D Scanning of existing objects",
        href: "scanning",
        icon: faCamera,
      },
      {
        text: "Printing",
        text2: "In-house FDM or SLA printing services",
        href: "printing",
        icon: faPrint,
      },
    ],
  },
  {
    text: "Work",
    href: "/work",
    subItems: [
      {
        text: "Previous Work",
        text2: "Hand-selected examples of our work",
        href: "examples",
        icon: faImage,
      },
      {
        text: "Types of Filament",
        text2: "The various types of filament on offer and their use cases",
        href: "filament",
        icon: faMoneyBill,
      },
    ],
  },
  {
    text: "Contact",
    href: "/contact",
    subItems: [
      {
        text: "Contact",
        text2: "Send us a contact message",
        href: "form",
        icon: faMessage,
      },
      {
        text: "Upload",
        text2: "Upload a 3D model for us to print",
        href: "upload",
        icon: faFileUpload,
      },
      {
        text: "FAQ",
        text2: "Frequently Asked Questions",
        href: "faq",
        icon: faQuestionCircle,
      },
    ],
  },
];

const hamburgerMenuItems = [
  menuItems[0],
  menuItems[1],
  menuItems[3],
  menuItems[4],
  menuItems[2],
];

const Navbar = () => {
  return (
    <motion.div
      initial={{ opacity: 0, transform: "translateY(-50px)" }}
      whileInView={{ opacity: 1, transform: "translateY(0px)" }}
      transition={{ duration: .5 }}
      className="bg-white z-50 relative"
      viewport={{ once: true }}
    >
      <div className="container mx-auto bg-white">
        <NavbarTop />
      </div>
      <hr />
    </motion.div>
  );
};
const variants = {
  open: { display: "flex", opacity: 1, left: "0px" },
  closed: { display: "none", opacity: 0, left: "-400%" },
};

const variantsBg = {
  open: { display: "flex", opacity: 0.6 },
  closed: { display: "none", opacity: 0 },
};

const NavbarTop = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <div className="navbarContainer relative mb-5 pb-5 p-[2.5rem] flex flex-column space-between items-center border-b border-white flex-wrap">
        <div className="flex flex-auto basis-0 group">
          <Link to="/">
            <img
              alt="logo"
              src={Logo}
              className="w-56 mr-2 min-w-[200px] group-hover:opacity-75 cursor-pointer transition ease-in-out duration-300"
            />
          </Link>
        </div>

        <div className="flex flex-auto justify-end basis-0 items-center">
          <NavbarBottom />

          <div
            onClick={() => setOpen(true)}
            className="hamburger group hover:cursor-pointer ml-6 lg:hidden"
          >
            <div className="h-0.5 w-6 mb-1 bg-black group-hover:bg-neutral-400 transition ease-in-out duration-300" />
            <div className="h-0.5 w-6 mb-1 bg-black group-hover:bg-neutral-400 transition ease-in-out duration-300" />
            <div className="h-0.5 w-6 mb-1 bg-black group-hover:bg-neutral-400 transition ease-in-out duration-300" />
          </div>
        </div>
      </div>

      {
        <motion.div
          animate={isOpen ? "open" : "closed"}
          variants={variantsBg}
          className="fixed h-full w-full top-0 left-0 z-49 bg-black"
        />
      }

      <motion.div
        animate={isOpen ? "open" : "closed"}
        variants={variants}
        className={`absolute top-6 mx-auto pl-6 pr-6 pb-6 left-0 right-0 h-auto drop-shadow-2xl  w-11/12 bg-neutral-50 rounded-md z-50`}
      >
        <div className="container">
          <div className="mt-4 mb-5 pb-5 flex flex-column space-between items-center border-b border-neutral-200 font-semibold">
            <div className="flex flex-auto basis-0 items-center text-neutral-400">
              <div className="text-left font-semibold mt-6">MENU</div>

              <div onClick={() => setOpen(false)} className="ml-auto">
                <FontAwesomeIcon
                  className="text-xl hover:text-neutral-400 transition ease-in-out duration-300 cursor-pointer"
                  icon={faXmark}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col">
            {hamburgerMenuItems.map((menuItem) => (
              <>
                {menuItem.subItems ? (
                  <>
                    <h1
                      key={menuItem.text}
                      className="text-neutral-400 pb-4 mb-4 border-b font-semibold border-neutral-200 uppercase"
                    >
                      {menuItem.text}
                    </h1>
                    {menuItem.subItems.map((subItem) => (
                      <Link
                        key={subItem.text}
                        to={`${menuItem.href}/${subItem.href}`}
                      >
                        <div className="h-18 flex bg-neutral-200 rounded-md w-full p-4 mb-6 flex-col">
                          <h3 className="font-semibold">{subItem.text}</h3>
                          <h4 className="text-neutral-500">{subItem.text2}</h4>
                        </div>
                      </Link>
                    ))}
                  </>
                ) : (
                  <Link key={menuItem.text} to={`${menuItem.href}`}>
                    <div className="h-18 font-semibold flex items-center bg-neutral-200 rounded-md w-full p-4 mb-6">
                      {menuItem.text}
                    </div>
                  </Link>
                )}
              </>
            ))}
          </div>
        </div>
      </motion.div>
    </>
  );
};

const NavbarBottom = () => {
  return (
    <div className="hidden lg:flex flex-column space-between items-center mr-12">
      <div className="flex grow justify-center space-x-12 items-center">
        {menuItems.map((menuItem) => (
          <span key={menuItem.text} className="group relative">
            <Link
              className="hover:text-neutral-400 text-neutral-600 font-semibold"
              to={menuItem.subItems ? "" : menuItem.href}
            >
              {menuItem.text}
            </Link>

            {menuItem.subItems && (
              <div className="menu absolute right-0 z-10">
                <div className="h-4 bg-transparent hidden group-hover:block" />

                <div className="p-6 bg-white text-neutral-700 shadow-xl rounded-lg border border-gray hidden group-hover:block testy">
                  <h5 className="text-neutral-500 text-xs font-bold mb-2 uppercase">
                    {menuItem.text}
                  </h5>
                  {menuItem.subItems.map((subItem) => (
                    <Link
                      key={subItem.text}
                      className="navItem"
                      to={`${menuItem.href}/${subItem.href}`}
                      replace
                    >
                      <div className="z-100 p-4 mb-2 rounded-md last:mb-0 flex flex-column justify-between items-center transition ease-in-out duration-300">
                        <div className="flex flex-row justify-center align-middle items-center">
                          <div className="pr-8 flex justify-center navItem-item">
                            <FontAwesomeIcon
                              className="text-xl w-6 transition ease-in-out duration-300"
                              icon={subItem.icon}
                            />
                          </div>

                          <div>
                            <h4 className="transition ease-in-out font-semibold duration-300 mb-1">
                              {subItem.text}
                            </h4>
                            <h3 className="text-neutral-400 leading-2">
                              {subItem.text2}
                            </h3>
                          </div>
                        </div>

                        <FontAwesomeIcon
                          className="text-neutral-500 pl-2 pr-2 transition ease-in-out duration-300"
                          icon={faChevronRight}
                        />
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            )}
          </span>
        ))}

        <span className="none lg:flex">
          <Link
            to="/upload"
            className="rounded-full font-mono uppercase text-sm bg-main text-white p-3 w-52 text-center"
          >
            <FontAwesomeIcon className="mr-2" icon={faFileUpload} />
            Upload a model
          </Link>
        </span>
      </div>
    </div>
  );
};

export default Navbar;
