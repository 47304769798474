import { Link } from 'react-router-dom';
import Logo from '../../img/logo.png';
import { menuItems, socials } from './Navbar';
import { motion } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Footer = () => {
    const arr = Array.from({ length: 50 });
    return (
        <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 1 }} viewport={{ once: true }}>
            <div className="overflow-hidden flex-nowrap text-neutral-300 font-mono uppercase flex flex-row relative z-1">
                { arr.map((x) => <h5 className="whitespace-nowrap">— 3D PRINTING CANBERRA —</h5>)}
            </div>
            <div className="w-screen bg-white text-white min-h-[400px] items-center justify-center align-center flex border-t border-gray">
                <div className="container mx-auto flex justify-center md:justify-start flex-wrap p-[2rem] text-black border-l border-gray">
                    <div className="w-full md:w-96 md:mr-24 mt-12 mb-12">
                        <h1 className="font-hero text-3xl font-semibold leading-tight tracking-tighter">Ready to get some work done?</h1>
                        <p className='mt-2'>
                            Feel free to get in touch with us or upload your model using our website. All work is done locally in Canberra and 
                            we offer fast, responsive & personalised service to our customers.
                        </p>

                        <div className="flex items-center mt-12 font-mono uppercase">
                            <Link to='/contact' className="inline-flex flex-row items-center text-white group transition-all align-center pt-2 pb-2 pr-4 pl-4 bg-main rounded-2xl font-semibold text-md">
                                <h3 className="group-hover:underline underline-offset-4 transition-all">
                                    Get In Touch
                                </h3>
                                <h3 className="ml-2 group-hover:ml-3 transition-all">
                                    ›
                                </h3>
                            </Link>

                            <Link to="/work" className="flex flex-row items-center text-main group transition-all align-center text-md ml-6">
                                <h3 className="group-hover:underline underline-offset-4 transition-all">
                                    See more work
                                </h3>
                                <h3 className="ml-2 group-hover:ml-3 transition-all">
                                    ›
                                </h3>
                            </Link>
                        </div>
                    </div>

                    <div className="flex w-full md:w-auto gap-6 md:mt-12 md:mb-12 flex-wrap">
                        <div className="w-48 md:pr-6 md:pb-6 md:pt-6">
                            <h4 className="font-hero text-md font-semibold leading-tight tracking-tight">Our Company</h4>

                            <h4 className="font-hero text-sm mt-2">Based in Canberra</h4>                            
                            <h4 className="font-hero text-sm mt-2">ABN: 70 717 583 120</h4>
                            <br/>
                            <a className="text-sm mt-1 mb-1 underline underline-offset-2 hover:opacity-75" href="mailto: 3DPCsales@gmail.com">
                                <h4>3DPCsales@gmail.com</h4>
                            </a>
                        </div>

                        <div className="w-48 md:pr-6 md:pb-6 md:pt-6 hidden md:block">
                            <h4 className="font-hero text-md font-semibold leading-tight tracking-tight">Sitemap</h4>
                            
                            <ul>
                                { menuItems.map((item) => 
                                    <>
                                        { 
                                            item.subItems 
                                                ?   <li>
                                                        <ul>
                                                        {
                                                            item.subItems.map((subItem) => 
                                                                <Link key={subItem.text} to={`${item.href}/${subItem.href}`}>
                                                                    <li>
                                                                        <h3 className="text-sm mt-1 mb-1 underline underline-offset-2 hover:opacity-75">{subItem.text}</h3>
                                                                    </li>
                                                                </Link>
                                                            )
                                                        }
                                                        </ul>
                                                    </li>
                                                :   <Link key={item.text} to={item.href}>
                                                        <li className="text-sm mt-1 mb-1 underline underline-offset-2 hover:opacity-75">{item.text}</li>
                                                    </Link>
                                        }
                                    </>
                                )}
                            </ul>
                        </div>

                        <div className="w-48 pr-6 pb-6 pt-6">
                            <h4 className="font-hero text-md font-semibold leading-tight tracking-tight">Social Media</h4>
                            <h4 className="font-hero text-sm mt-2">Find & connect with us on social media.</h4>         
                            <br/>

                            { 
                                socials.map((social) => 
                                    <a key={social.id} className="mr-2 group text-3xl" target="_blank" rel="noreferrer" href={social.href}>
                                        <FontAwesomeIcon className="group-hover:text-neutral-400 transition ease-in-out duration-300" icon={social.icon}/>
                                    </a>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-screen bg-white border-t border-gray p-0 m-0 text-black items-center justify-center align-center flex">
                <div className="container mx-auto flex justify-center flex-wrap items-center p-8">
                    <div className="w-full md:w-1/2 flex flex-col justify-center items-center text-center">
                        <img alt="logo" src={Logo} className="w-52 group-hover:opacity-75 cursor-pointer transition ease-in-out duration-300"/>
                        <h4 className="text-sm mt-3">Copyright © {new Date().getFullYear()} 3D Printing Canberra — Designs bound only by your imagination.</h4>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default Footer;